<template>
  <div class="main">
    <div class="app-wrapper">
      <div class="machine_title">{{ $t("Custom.Custom") }}</div>
      <div class="machine_search">
        <div class="machine_search_list">
          <div class="search_title">{{ $t("Custom.CustomName") }}</div>
          <div class="search_selected">
            <el-input v-model="searchForm.company" :placeholder="$t('Custom.CompanyName')" clearable />
          </div>
        </div>
        <div class="machine_search_list">
          <div class="search_title">{{ $t("Login.Account") }}</div>
          <div class="search_selected">
            <el-input v-model="searchForm.account" :placeholder="$t('Custom.Account')" clearable />
          </div>
        </div>
        <div class="machine_search_btn">
          <el-button type="primary" round @click="handleSearch">{{
        $t("Machine.Search")
      }}</el-button>
        </div>
        <div class="machine_search_btn" style="margin-left: 5px">
          <el-button type="primary" round @click="handleReset">
            {{ $t("Warning.Reset") }}
          </el-button>
        </div>
        <div class="machine_search_space"></div>
        <div class="machine_search_btn">
          <el-button type="primary" round @click="handleAdd">{{
        $t("Machine.Add")
      }}</el-button>
        </div>
      </div>
      <div class="table_content">
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark"
          :header-cell-style="{ background: '#dde0e8' }" highlight-current-row style="width: 100%">
          <el-table-column width="50" align="center" />
          <el-table-column min-width="20%" :label="$t('yudata.company')" show-overflow-tooltip>
            <template slot-scope="scope">
              <div>{{ scope.row.company }}</div>
            </template>
          </el-table-column>
          <el-table-column min-width="20%" :label="$t('UserInfo.UserName')" show-overflow-tooltip>
            <template slot-scope="scope">
              <div>{{ scope.row.name }}</div>
            </template>
          </el-table-column>
          <el-table-column min-width="20%" :label="$t('Login.Account')" show-overflow-tooltip>
            <template slot-scope="scope">
              <div>{{ scope.row.account }}</div>
            </template>
          </el-table-column>
          <el-table-column min-width="20%" :label="$t('Login.MobilePhone')" show-overflow-tooltip>
            <template slot-scope="scope">
              <div>{{ scope.row.phone }}</div>
            </template>
          </el-table-column>
          <el-table-column min-width="20%" :label="$t('Custom.Email')" show-overflow-tooltip>
            <template slot-scope="scope">
              <div>{{ scope.row.email }}</div>
            </template>
          </el-table-column>

          <el-table-column min-width="20%" :label="$t('Setting.CreationTime')" show-overflow-tooltip>
            <template slot-scope="scope">
              <div>{{ handle_time(scope.row.create_time) }}</div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('AddMachine.Operation')" width="120" align="left">
            <template slot-scope="scope">
              <img src="../../assets/image/bianji_main@2x.png" @click="handleEdit(scope.row)"
                class="OperationImg mgr" />
              <!-- <img
                src="../../assets/image/shanchu_mian@2x.png"
                @click="handleOpenDeleteConfirmDialog(scope.$index, scope.row)"
                class="OperationImg mgr"
              /> -->
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination-container">
        <el-pagination background layout="prev, pager, next, sizes" :page-size="pagesize" :page-sizes="[5, 10, 15]"
          :current-page.sync="page" :total="total" v-show="total && total > 0" @size-change="handleSizeChange"
          @current-change="handleCurrentChange" />
      </div>
      <el-dialog :title="type == 'edit' ? $t('Custom.EditCompany') : $t('Custom.AddCompany')
        " :visible.sync="dialogVisible" width="600px" class="dialog" :close-on-click-modal="false">
        <el-form ref="customerInfoForm" :model="customInfo" label-width="0px" autocomplete="off"
          :rules="customerInfoFormRules">
          <div class="form-box">
            <el-form-item prop="account">
              <div class="label" style="text-align: left">
                {{ $t("Login.Account") }}
              </div>
              <el-input v-model.trim="customInfo.account" :placeholder="$t('Setting.IptAccout')" auto-complete="off"
                clearable :disabled="type === 'edit'" />
            </el-form-item>
          </div>
          <div class="form-box">
            <el-form-item prop="company">
              <div class="label" style="text-align: left">
                {{ $t("Custom.Company") }}
              </div>
              <el-input v-model="customInfo.company" :placeholder="$t('Custom.CompanyName')" auto-complete="off"
                clearable />
            </el-form-item>
          </div>
          <div class="form-box">
            <el-form-item prop="name">
              <div class="label" style="text-align: left">
                {{ $t("UserInfo.UserName") }}
              </div>
              <el-input v-model="customInfo.name" :placeholder="$t('Setting.IpUserName')" auto-complete="off"
                clearable />
            </el-form-item>
          </div>
          <div class="form-box" v-show="type == 'add'">
            <el-form-item prop="password">
              <div class="label" style="text-align: left">
                {{ $t("Login.Password") }}
              </div>
              <el-input v-model.trim="customInfo.password" :placeholder="$t('Setting.IpPwd')" show-password
                auto-complete="off" type="password" clearable />
            </el-form-item>
          </div>
          <div class="form-box">
            <el-form-item prop="phone">
              <div class="label" style="text-align: left">
                {{ $t("Login.MobilePhone") }}
              </div>
              <el-input v-model.trim="customInfo.phone" :placeholder="$t('Login.phoneNumber')" auto-complete="off"
                clearable />
            </el-form-item>
          </div>
          <div class="form-box">
            <el-form-item prop="email">
              <div class="label" style="text-align: left">
                {{ $t("Login.Email") }}
              </div>
              <el-input v-model.trim="customInfo.email" :placeholder="$t('Setting.IptEmail')" auto-complete="off"
                clearable />
            </el-form-item>
          </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="info" round @click="dialogVisible = false">
            {{ $t("AddMachine.Cancel") }}</el-button>
          <el-button type="primary" round @click="handleSubmit" :loading="confirmBtnLoading">
            {{ $t("Login.OK") }}</el-button>
        </span>
      </el-dialog>
      <el-dialog :title="$t('Custom.AdPower')" :visible.sync="dialogVisibles" width="364px" class="dialog dialogDelete">
        <!-- class="dialog" -->
        <el-form ref="deleteFormRef" label-width="0px" :model="deleteForm" :rules="deleteFormRules">
          <div class="form-box">
            <el-form-item prop="password">
              <div class="label" style="text-align: left">
                {{ $t("Custom.AdPwd") }}
              </div>
              <el-input v-model.trim="deleteForm.password" :placeholder="$t('Custom.pwd')" auto-complete="off"
                type="password" show-password clearable />
            </el-form-item>
          </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button round type="info" @click="dialogVisibles = false">{{
        $t("AddMachine.Cancel")
      }}</el-button>
          <!-- 输入密码 加一个enter快捷键 -->
          <el-button round type="primary" @click="handleSubmitDeleteForm">{{
        $t("Login.OK")
            }}</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import "../../style/table.less";
import {
  getCustomer,
  addCustomer,
  editCustomer,
  deleteCustomer,
} from "@/api/custom";
import { handleTime, awaitWraper, showMessage } from "../../js/util";
import { reactive, getCurrentInstance, ref, nextTick, onMounted } from "vue";
import i18n from "../../lang";
import Constant from "../../js/constant";
export default {
  name: "Custom",
  setup() {
    const { proxy: that } = getCurrentInstance();
    const searchForm = reactive({
      company: "",
      account: "",
    });
    const handleReset = () => {
      for (let key of Object.keys(searchForm)) {
        searchForm[key] = "";
      }
      that.getCustomerList();
    };
    const handleSearch = () => {
      that.getCustomerList();
    };

    // 表单
    const customInfo = reactive({
      name: "", // 用户名称
      account: "", //账号
      password: "", //密码
      phone: "", // 手机
      email: "", //邮箱
      company: "", //公司名称
      id: "",
    });

    const dialogVisible = ref(false);
    const customerInfoForm = ref("");

    const customerInfoFormRules = {
      account: [
        {
          required: true,
          validator: function (rule, value, callback) {
            // 必填
            if (!value) {
              callback(new Error(i18n.t("Setting.IptAccout")));
            } else if (!Constant.account_reg.test(value)) {
              callback(new Error(i18n.t("yudata.account_reg")));
            } else {
              callback();
            }
          },
          trigger: ["blur"],
        },
      ],
      company: [
        {
          required: true,
          validator: function (rule, value, callback) {
            // 必填
            if (!value) {
              callback(new Error(i18n.t("Custom.CompanyName")));
            } else if (!Constant.threeToTwenty.test(value)) {
              callback(new Error('3-20个字符'));
            } else {
              callback();
            }
          },
          trigger: ["blur"],
        },
      ],
      name: [
        {
          required: true,
          validator: function (rule, value, callback) {
            // 必填
            if (!value) {
              callback(new Error(i18n.t("Setting.IpUserName")));
            } else if (!Constant.threeToTwenty.test(value)) {
              callback(new Error('3-20个字符'));
            }else {
              callback();
            }
          },
          trigger: ["blur"],
        },
      ],
      password: [
        {
          required: true,
          validator: function (rule, value, callback) {
            // 必填
            if (!value) {
              callback(new Error(i18n.t("Setting.IpPwd")));
            } else if (!Constant.password_reg.test(value)) {
              callback(new Error(i18n.t("yudata.password_reg")));
            } else {
              callback();
            }
          },
          trigger: ["blur"],
        },
      ],
      phone: [
        {
          required: true,
          validator: function (rule, value, callback) {
            // 必填
            if (!value) {
              callback(new Error(i18n.t("Login.phoneNumber")));
            } else if (!Constant.phone_reg.test(value)) {
              callback(new Error(i18n.t("Tip.Format")));
            } else {
              callback();
            }
          },
          trigger: ["blur", "change"],
        },
      ],
      email: [
        {
          required: true,
          validator: function (rule, value, callback) {
            // 必填
            if (!value) {
              callback(new Error(i18n.t("Setting.IptEmail")));
            } else if (!Constant.email_reg.test(value)) {
              callback(new Error(i18n.t("Setting.ErrEmail")));
            } else {
              callback();
            }
          },
          trigger: ["blur"],
        },
      ],
    };
    //表单类型
    const type = ref("add");
    const handleAdd = () => {
      dialogVisible.value = true;
      type.value = "add";
      for (let key of Object.keys(customInfo)) {
        customInfo[key] = "";
      }
      // // 清空表单
      nextTick(() => {
        customerInfoForm.value.clearValidate?.();
      });
    };
    const handleEdit = (row) => {
      dialogVisible.value = true;
      nextTick(() => {
        customerInfoForm.value.clearValidate?.();
      });
      type.value = "edit";
      for (let key of Object.keys(customInfo)) {
        customInfo[key] = row[key] ?? "";
      }
      // // password 留空 无法解密
      // customInfo.password = "";
    };
    const confirmBtnLoading = ref(false);
    const handleSubmit = () => {
      let targetForm, fnc;
      // 新增
      if (type.value == "add") {
        customerInfoForm.value.validate(async (valid, failFiled) => {
          if (valid) {
            if (type.value == "add") {
              // 新增
              const { id, ...otherForm } = customInfo;
              targetForm = otherForm;
              targetForm.password = that.$md5(targetForm.password);
              fnc = addCustomer;
            }
            confirmBtnLoading.value = true;
            const [error, res] = await awaitWraper(fnc(targetForm));
            confirmBtnLoading.value = false;
            if (error) return;
            showMessage(res.message);
            dialogVisible.value = false;
            handleReset();
          } else {
            console.log("error submit!!2222");
            return false;
          }
        });
      } else {
        // 编辑
        let validatedResCount = 0;
        customerInfoForm.value.validateField(
          ["account", "company", "email", "name", "phone"],
          async (valid) => {
            if (!valid) {
              validatedResCount += 1;
              if (validatedResCount == 5) {
                console.log("校验通过");
                const { password, ...otherForm } = customInfo;
                targetForm = Object.assign({}, customInfo);
                // 编辑的时候密码传空  即不修改密码
                targetForm.password = "";
                fnc = editCustomer;
                confirmBtnLoading.value = true;
                const [error, res] = await awaitWraper(fnc(targetForm));
                confirmBtnLoading.value = false;
                if (error) return;
                showMessage(res.message);
                dialogVisible.value = false;
                handleReset();
              }
            } else {
              console.log("error submit!!2222");
              return false;
            }
          }
        );
      }

      // customerInfoForm.value.validate(async (valid, failFiled) => {
      //   if (valid) {
      //     let targetForm, fnc;
      //     if (type.value == "add") {
      //       // 新增
      //       const { id, ...otherForm } = customInfo;
      //       targetForm = otherForm;
      //       targetForm.password = that.$md5(targetForm.password);
      //       fnc = addCustomer;
      //     } else {
      //       const { password, ...otherForm } = customInfo;
      //       targetForm = Object.assign({}, customInfo);
      //       // 编辑的时候密码传空  即不修改密码
      //       targetForm.password = "";
      //       fnc = editCustomer;
      //     }
      //     confirmBtnLoading.value = true;
      //     const [error, res] = await awaitWraper(fnc(targetForm));
      //     confirmBtnLoading.value = false;

      //     if (error) return;
      //     showMessage(res.message);
      //     dialogVisible.value = false;
      //     handleReset();
      //   } else {
      //     console.log("error submit!!2222");
      //     return false;
      //   }
      // });
    };
    const tableData = ref([]);
    const total = ref(null);
    const getCustomerList = async () => {
      const loading = that.$loading({
        lock: true,
        text: that.$t("Tip.Loading"),
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const params = {};
      params.page = that.page;
      params.pagesize = that.pagesize;
      const [err, res] = await awaitWraper(
        getCustomer({ ...params, ...that.searchForm })
      );
      loading.close();
      if (err) return;
      tableData.value = res.data.data;
      total.value = res.data.total;
    };
    onMounted(() => {
      getCustomerList();
    });
    const page = ref(1);
    const pagesize = ref(15);
    const handleSizeChange = (val) => {
      page.value = 1;
      pagesize.value = val;
      getCustomerList();
    };
    const handleCurrentChange = (val) => {
      page.value = val;
      getCustomerList();
    };

    const deleteForm = reactive({
      password: "",
      id: "",
    });
    const deleteFormRules = {
      password: [
        {
          required: true,
          validator: function (rule, value, callback) {
            // 必填
            if (!value) {
              callback(new Error(i18n.t("Setting.IpPwd")));
            } else if (!Constant.password_reg.test(value)) {
              callback(new Error(i18n.t("yudata.password_reg")));
            } else {
              callback();
            }
          },
          trigger: ["blur"],
        },
      ],
    };
    const deleteFormRef = ref(null);
    const dialogVisibles = ref(false);
    const resetDeleteForm = () => {
      for (const key of Object.keys(deleteForm)) {
        deleteForm[key] = "";
      }
    };
    // 删除
    const handleOpenDeleteConfirmDialog = (idx, data) => {
      // 每次都要清空输入密码表单
      resetDeleteForm();
      dialogVisibles.value = true;
      nextTick(() => {
        deleteFormRef.value.clearValidate?.();
      });
      deleteForm.id = data?.id;
    };
    const handleSubmitDeleteForm = () => {
      // 删除前输入管理员密码
      // 点确定 调用删除接口
      // 未输入密码 提示输入密码
      deleteFormRef.value.validate(async (valid) => {
        if (valid) {
          const newPassword = that.$md5(deleteForm.password);
          console.log({ ...deleteForm, password: newPassword });
          const [err, res] = await awaitWraper(
            deleteCustomer({ ...deleteForm, password: newPassword })
          );
          if (err) return;
          showMessage(res.message);
          dialogVisibles.value = false;
          handleSearch();
        } else {
        }
        return false;
      });
    };
    const handle_time = (time) => {
      return handleTime(time);
    };
    return {
      searchForm,
      customInfo,
      dialogVisible,
      customerInfoForm,
      type,
      customerInfoFormRules,
      confirmBtnLoading,
      tableData,
      total,
      page,
      pagesize,
      deleteForm,
      deleteFormRules,
      deleteFormRef,
      dialogVisibles,
      resetDeleteForm,
      handleOpenDeleteConfirmDialog,
      handleReset,
      handleSearch,
      handleAdd,
      handleEdit,
      handleSubmit,
      getCustomerList,
      handleSizeChange,
      handleCurrentChange,
      handleSubmitDeleteForm,
      handle_time,
    };
  },
};
</script>

<style lang="less" scoped>
@media (max-width: 1367px) {
  .dialog /deep/ .el-input__suffix {
    right: 20px !important;
  }

  .dialogs /deep/ .el-dialog {
    margin-top: 12vh !important;
  }
}

@media (min-width: 1365px) and (max-width: 1441px) {
  .dialog /deep/ .el-input__suffix {
    right: 20px !important;
  }
}

.pagination-container {
  margin: 20px 25px;
  float: left;
}

.mgr {
  margin-right: 0px !important;
  margin-left: 10px;
}

.dialog /deep/ input::-webkit-outer-spin-button,
.dialog /deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.dialog /deep/ .el-dialog {
  border-radius: 20px !important;
  background: #e7e9ee;
}

.dialog /deep/ .el-dialog__body {
  padding: 0px 30px 10px 30px;
}

.dialog /deep/ input[type="number"] {
  -moz-appearance: textfield;
}

.dialog /deep/ .el-dialog {
  border-radius: 20px !important;
  background: #e7e9ee;
  filter: drop-shadow(0 6px 6px rgba(0, 0, 0, 0.16));
}

.dialog /deep/ .el-form-item__content {
  line-height: 35px;
  font-size: 18px;
}

.dialog /deep/ .el-dialog__body {
  padding: 0px 20px 10px 20px;
}

.dialog /deep/ .el-input__suffix {
  right: 20px;
}

.dialogDelete /deep/ .el-dialog {
  margin-top: 30vh !important;
}

.dialog /deep/ .el-input__suffix {
  top: 2px;
}

.dialog .userRole /deep/ .el-input__suffix {
  right: 20px !important;
}

.btn-normal {
  width: 70px;
  height: 32px;
  border-radius: 20px;
  font-size: 13px;
  line-height: 34px;
  font-weight: 400;
  text-align: center;
  color: #fff;
  float: right;
  margin-left: 10px;
  cursor: pointer;
}

.dialog /deep/ .el-input__inner {
  height: 32px !important;
  line-height: 32px !important;
}

.dialog /deep/ .el-select__tags {
  height: auto !important;
  flex-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dialog /deep/ .el-select {
  width: 100%;
}

.dialog /deep/ .el-select .el-input--suffix {
  width: 100% !important;
  padding-right: 15px;
}

.dialog /deep/ .plan-form.r3 .label {
  font-size: 16px;
}

.dialog /deep/ .el-input--suffix {
  width: 100% !important;
  padding-right: 15px;
}

.dialog /deep/ .el-input--suffix {
  width: 100% !important;
  padding-right: 15px;
}

.dialog /deep/ .plan-form .row .form-item {
  width: 33.4% !important;
}

.dialog /deep/ .el-input__icon {
  line-height: 35px;
}

.dialog /deep/ .el-dialog__title {
  font-weight: bold;
  font-size: 20px;
}

.dialog /deep/ .el-dialog__body {
  padding: 20px 20px !important;
}

.dialog /deep/ .el-dialog__header {
  padding: 20px 20px 10px !important;
  text-align: left;
}

.dialog /deep/ .el-dialog__headerbtn {
  font-size: 30px !important;
  top: 12px;
}

.dialog /deep/ .el-dialog__footer {
  padding: 0px 20px 15px !important;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}

.noData {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 500px;

  position: absolute;
  flex-direction: column;
  left: 0;
  top: 200px;

  .empty_text {
    line-height: 20px !important;
    font-weight: bold;
    font-size: 16px;
    color: #909399;
  }
}

.pagination-container {
  margin: 20px 25px;
  float: left;
}

.app-wrapper /deep/ .el-table__empty-text {
  line-height: 20px !important;
  font-weight: bold;
  font-size: 16px;
  color: #909399;
}

.main {
  width: 100%;
  height: 100%;
  background-color: #ebecee;

  .app-wrapper {
    background-color: #ebecee;
    height: 100%;

    .table_content {
      height: calc(100vh - 200px);
      overflow: auto;

      /deep/ .el-table__body-wrapper {
        height: auto;
        overflow-y: auto;
        background-color: #ebecee;
        overflow-x: hidden;
      }

      /deep/ .el-table tr {
        background-color: #ebecee !important;
      }
    }

    .machine_title {
      padding: 22px 20px;
      color: #414141;
      font-size: 24px;
      font-weight: 700;
      text-align: left;
    }

    .machine_search {
      display: flex;
      flex-direction: row;
      padding: 0px 25px;

      .machine_search_list {
        margin-right: 20px;

        // flex: 2.5;
        .search_title {
          float: left;
          text-align: left;
          height: 32px;
          line-height: 32px;
          margin: 10px 10px 10px 0;
          color: #4d4f5c;
          font-size: 18px;
        }

        .search_selected {
          float: left;
          margin: 10px 10px 10px 0;

          /deep/ .el-date-editor.el-input,
          /deep/ .el-date-editor.el-input__inner {
            width: 100%;
          }

          /deep/ .el-input__inner {
            height: 32px;
            line-height: 32px;
          }

          /deep/ .el-input__suffix-inner .el-input__icon {
            margin-top: -2px;
          }

          /deep/ .el-input__icon {
            margin-top: 5px;
            color: #a4afb7;
            font-weight: bold;
          }

          /deep/ .input-wrapper .el-input__icon {
            margin-top: 2px;
          }

          /deep/ .el-select .el-input .el-select__caret.is-reverse {
            margin-top: -3px;
          }
        }
      }

      .machine_search_btn {
        flex: 0.55;
        display: flex;
        align-items: center;

        .edi_color {
          background-color: #767e95;
        }
      }

      .machine_search_space {
        flex: 5.4;
      }
    }
  }
}
</style>
